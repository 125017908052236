<template>
    <div>
      <!-- <input type="text" v-model="columnFilterFn"/>
      <input type="text" v-model="columnFilterFn2"/> -->
      <select  v-model="columnFilterFn" @change="filterData">
        <option v-for='data in arr' :key="data">{{data}}</option>
      </select>
      <vue-good-table
      :columns="columns"
      :rows="rows">
        <!-- <template></template> -->
      </vue-good-table>
    </div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
export default {
// add to component
components: {
  VueGoodTable,
},
 data(){
    return {
      columnFilterFn:'',
      columnFilterFn2:"",
      arr: ["John","Jane","Chris"],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: ["John","Jane","Chris"], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
        },
        },
        {
          label: 'Age',
          field: 'age',
          type: 'number',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [20,24,16], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn2, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
        },
        },
        {
          label: 'Created On',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'MMM do yy',
        },
        {
          label: 'Percent',
          field: 'score',
          type: 'percentage',
        },
      ],
      rows: [
        { id:1, name:"John", age: 20, createdAt: '',score: 0.03343 },
        { id:2, name:"Jane", age: 24, createdAt: '2011-10-31', score: 0.03343 },
        { id:3, name:"Susan", age: 16, createdAt: '2011-10-30', score: 0.03343 },
        { id:4, name:"Chris", age: 55, createdAt: '2011-10-11', score: 0.03343 },
        { id:5, name:"Dan", age: 40, createdAt: '2011-10-21', score: 0.03343 },
        { id:6, name:"John", age: 24, createdAt: '2011-10-31', score: 0.03343 },
      ],
    };
 },
  methods: {
filterFn: function(data, filterString) {
  var x = parseInt(filterString)
  return data >= x - 5 && data <= x + 5;
}
  }
}
</script>